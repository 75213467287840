<template>
  <div>
    <div class="cash_title" style="background:none;">
        <img class="cash_back" src="img/backb.png" alt="" @click="closePage">
        <span>账号注销协议</span>
    </div>
    <div style="padding:0 20px;">
      <p>【特别说明】亲爱的用户，在您正式开始注销流程前，我们先为您做出如下说明：注销多乐账号 后，您将无法再以此账号登录和使用多乐的相关服务，除 法律法规或本协议另有规定外，您在该账号下的个人信息将 进行删除处理或匿名化处理，且此前已关联该游戏账号的相 关产品与服务将不再关联。该账号一旦注销完成，将无法恢复，请您在注销前慎重考虑。</p>
      <p>若您经过慎重考虑后仍决定注销多乐账号的，请您务必先 行仔细阅读和充分理解本《账号注销协议》（“本协议”）， 尤其是本协议免责、限制条款及争议解决方式和管辖条款， 前述条款可能以黑体加粗、颜色标记或其他合理方式提示您 注意。在同意全部内容后，按照我们的注销操作指引点击进 行下一步操作，您勾选本注销协议并点击下一步注销操作的 行为即视为您已经同意签署和遵守本协议全部内容。如您在 注销后需要再次使用我们的服务的，欢迎您使用手机号码或多乐允许的其他方式重新注册。</p>
      <p>（如您仍决定注销多乐账号的,请继续向下阅读）</p>
      <div class="title">一、首先您需要知悉的是：账号注销将会对您产生如下影响：</div>
      <p style="text-indent: unset;">1.1	多乐账号一旦注销，您将无法登录、使用该账号（也包括无法再使用该账号作为第三方账号登录其他产品），也就是说您将无法再以此账号登录/使用/继续使用第三方的相关 产品与服务；</p>
      <p style="text-indent: unset;">1.2 多乐账号一旦注销，您曾通过该账号登录、使用的产品与服务下的所有内容、信息、数据、记录将会被删除或匿名化处理，您也无法再检索、访问、获取、继续使用和找回 （但法律法规另有规定或监管部门另有要求的除外），包 括：</p>
      <p>该账号下的个人资料（例如：头像、昵称等）及绑定信息 （例如：绑定手机号、邮箱等）；</p>
      <p>该账号下的您的个人信息（同时您亦不再享有《多乐隐私政策》中约定的您的用户权利，但法律法规另有规定的除外）；</p>
      <p>该账号曾发表的所有内容（例如：音频、图片、照片、评论、互动、点赞等）；</p>
      <p>其他所有内容、信息、数据、记录。</p>
      <p style="text-indent: unset;">1.3您申请账号注销的行为即表明您同意放弃该账号在多乐使用期间已产生的但未消耗完毕的权益及未来的预期利益。即多乐账号一旦注销，我们将会对该账号下的全部权 益做清除处理，包括：</p>
      <p>您尚未到期的各类VIP会员权益、等级等；</p>
      <p>您尚未使用的积分、卡券、优惠券、代金券、成长值等；</p>
      <p>您在多乐中发布的内容未来的收益权益；</p>
      <p>您在多乐中的各类身份权益；</p>
      <p>其他已产生但未消耗完毕的权益或未来预期的收益。</p>
      <p style="text-indent: unset;">1.4	多乐账号一旦注销完成，将无法恢复（即使您在注销 后以同一手机号码再次向我们申请注册多乐账号，此时该 账号为新的用户账号。因此，我们善意地提醒您在申请注销 前对您认为需要备份的内容提前自行做好备份）；</p>
      <p style="text-indent: unset;">1.5	多乐账号一旦注销，您与我们签署的有关本产品（或 服务）的的相关用户协议、其他权利义务性文件等相应终止（但我们与您之间已约定继续生效的或法律法规另有规定的 除外）。但前述服务协议的终止并不影响该账号注销前您使 用本产品（或服务）应当承担的相关责任，您仍需要对该账 号注销前使用本产品（或服务）的行为承担相应责任，包括 但不限于可能产生的违约责任、损害赔偿责任及履约义务 等。</p>
      <p style="text-indent: unset;">1.6	其他因多乐账号注销（可能）产生的结果。</p>
      <div class="title">二、在您向我们申请/继续申请注销多乐账号之前，为保障 您的账号安全和相关权益，您需先行检查，并确保和承诺您 申请注销的游戏账号已经同时满足以下条件，这些条件包括 但不限于：</div>
      <p style="text-indent: unset;">2.1	该账号为您本人合法拥有并登录使用，且符合《多乐用户协议》及相关说明规则中有关多乐账号的规定;</p>
      <p style="text-indent: unset;">2.2	该账号处于安全及正常使用状态：自您申请注销开始前 的60天内无修改密码、修改绑定手机/邮箱等敏感操作，且账号未处于违规处理（违规处理指账号因违反平台相关协议、 规则被采取封号及其他处理措施）期间。</p>
      <p style="text-indent: unset;">2.3	该账号中的相关收益已结清或得到妥善处理：包括但不 限于点券、金币、银币，收益，花花，道具、会员权益及其他收益等。请您妥善处 理您的收益，若注销时未处理，视为您自愿放弃该等收益；</p>
      <p style="text-indent: unset;">2.4	该账号无未完成状态订单：包括但不限于基于该账号产 生的有关虚拟货币、虚拟道具、周边商品等的订单；</p>
      <p style="text-indent: unset;">2.5	该账号无任何纠纷：包括投诉举报、被投诉举报、国家 机关调查、仲裁、诉讼等纠纷；</p>
      <p style="text-indent: unset;">2.6	其他为了保障您的账号安全和财产权益需要满足的内容。</p>
      <p>上述问题您可首先尝试自行处理，如您无法处理的或在处理 过程中有任何疑惑的，可联系多乐客服协助处理。</p>
      <div class="title">三、如何注销多乐账号？</div>
      <p style="text-indent: unset;">3.1	您需按照我们的官方渠道提供的注销流程和操作指引， 在相关功能设置页面进行注销操作；</p>
      <p style="text-indent: unset;">3.2	您已经同意本注销协议的全部内容；同时，您已经检查与确认满足了本协议第二条的要求；</p>
      <p style="text-indent: unset;">3.3	为进一步保障您的相关合法权益，我们有权根据您的账号安全状态以及您的相关产品与服务的使用情况等综合判断您的账号是否符合注销条件。同时，为保障您的账号安全和 财产权益，我们有权进行注销申请的身份验证以判断您是该 账号的有权使用人（如手机验证方式等）。如我们在综合判 断后发现该账号不符合注销条件的，或者身份验证失败的， 您的注销可能会失败，您可以按照我们的指引进行后续操作 或联系多乐客服协助处理。即使我们判断您符合注销条件 的，也是基于您的承诺（即您自身确保账号符合上述注销条 件的承诺）进行的形式审查，仍由您自身对账号符合上述注 销条件承担保证责任，我们对此不承担相应的法律责任；</p>
      <p style="text-indent: unset;">3.4  我们在您成功完成上述第3.3点的“身份验证”后，视为您 已成功向我们提交了账号注销申请，我们将在15日内完成账 号注销。为确保注销的顺利完成，请您在账号注销期间不要 使用该账号登录多乐，否则视为您撤销注销该账号申请。 账号注销成功后，该账号下的个人信息将被删除或匿名化处 理（但法律法规及本协议另有规定除外）。</p>
      <p>具体的注销流程化和操作以本产品（或服务）官方公布的为 准。您理解并同意，在您申请注销账号期间，如我们需要对 相关交易、投诉或其他与您的账号相关的事项进行核实的， 或者我们有其他合理的理由的，我们有权冻结您的该账号注 销申请且暂时不为您提供注销服务。</p>
      <div class="title">四、其他</div>
      <p style="text-indent: unset;">4.1	本协议签订地为中华人民共和国湖北省。</p>
      <p style="text-indent: unset;">4.2	本协议的成立、生效、履行、解释及纠纷解决，适用中 华人民共和国大陆地区法律（不包括冲突法）。</p>
      <p style="text-indent: unset;">4.3	若您和多乐之间因本协议发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本 协议签订地有管辖权的人民法院管辖。</p>
      <p style="text-indent: unset;">4.4	如在账号注销过程中有任何问题您无法处理的或在处理 过程中有任何疑惑的，可联系多乐客服协助处理。</p>
    </div>
  </div>
</template>

<script>
import "@/assets/css/game.css"
export default {
  name: 'logout',
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    closePage(){
        let params = '调用'
        this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
        })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    text-indent:56px;
    color: rgba(0,0,0,.8);
    word-break: break-all;
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
}
</style>
